import axios from 'axios';
import {useState, useEffect} from "react"
import './App.css';
import React from 'react';
import TodoList from './TodoList';
import Kanye from './Kanye';





function App() {
  return (
    <div className='App'>
        <Kanye/>
    </div>
  );
}

export default App;
